export default {
  constantPaymentOptions: {
    value: {
      CASH: 'Cash through AMAN',
      Fawry: 'Cash Through Fawry',
      Mobile_Wallet: 'Mobile Wallet',
      CREDIT_CARD: 'Credit & Debit Card',
      QASSATLY: 'Qassatly',
      Free: "Free",
      MEEZA: "Meeza",
      PaySky: "Card Payments",
      NBE_6MONTHS: "6 Months - NBE",
      NBE_12MONTHS: "12 Months - NBE",
      NBE_18MONTHS: "18 Months - NBE",
      NBE_GENERAL: "NBE Installments",
      BM_6MONTHS: "6 Months - BM",
      BM_12MONTHS: "12 Months - BM",
      BM_18MONTHS: "18 Months - BM",
      INSTALLMENTS_6MONTHS: "6 Months - Installments",
      INSTALLMENTS_12MONTHS: "12 Months - Installments",
      INSTALLMENTS_18MONTHS: "18 Months - Installments",
      OFFLINE_PAYMENT: "Offline Payment",
      VALU : "ValU",
      SOUHOOLA : "Souhoola",
      Aman_Installments:"Aman Installments",
      Contact: "Contact",
      HALAN : "Halan",
      Cash_API: "Cash API",
      Installments:"Installments (Old)",
      Mogo: "Mogo",
      Blnk: "Blnk",
    },
    name: {
      CASH: 'Cash through AMAN',
      Fawry: 'Cash Through Fawry',
      Mobile_Wallet: 'Mobile Wallet',
      CREDIT_CARD: 'Credit & Debit Card',
      QASSATLY: 'Qassatly',
      Free: "Free",
      MEEZA: "Meeza",
      PaySky: "Card Payments",
      NBE_6MONTHS: "6 Months - NBE",
      NBE_12MONTHS: "12 Months - NBE",
      NBE_18MONTHS: "18 Months - NBE",
      NBE_GENERAL: "NBE Installments",
      BM_6MONTHS: "6 Months - BM",
      BM_12MONTHS: "12 Months - BM",
      BM_18MONTHS: "18 Months - BM",
      BM_GENERAL: "BM Installments",
      INSTALLMENTS_6MONTHS: "6 Months - Installments",
      INSTALLMENTS_12MONTHS: "12 Months - Installments",
      INSTALLMENTS_18MONTHS: "18 Months - Installments",
      OFFLINE_PAYMENT: "Offline Payment",
      VALU : "ValU",
      SOUHOOLA : "Souhoola",
      Contact: "Contact",
      HALAN : "Halan",
      Aman_Installments:"Aman Installments",
      Cash_API: "Cash API",
      Installments:"Installments (Old)",
      Mogo: "Mogo",
      Blnk: "Blnk",
    }
  },
  constantgatePaymentOptions: {
    CREEDIT_CARD: 'credit_card',
    CASH: 'Cash',
    MIGS_CREEDIT_CARD: "Credit Card - MIGS",
    MPGS_CREEDIT_CARD: "Credit Card - MPGS",
    Credit_Card: "Credit Card"
  },
  creditCardPaymentOptions: ['Credit & Debit Card', 'Card Payments'],
}
